import React from 'react';
import '../App.css';

const members = [
  { name: 'Alda Pinto', workplace: 'ULSCoimbra' },
  { name: 'Bruno Esteves', workplace: 'ULSCoimbra' },
  { name: 'Carla Solano', workplace: 'ULSCoimbra' },
  { name: 'Daniel Matos', workplace: 'ULSCoimbra' },
  { name: 'Elza Santos', workplace: 'ULSCoimbra' },
  { name: 'Marta Rosa', workplace: 'ULSCoimbra' },
  { name: 'Paula Marques', workplace: 'ULSCoimbra' },
  { name: 'Tiago Araújo', workplace: 'ULSCoimbra' },
  { name: 'Tiago Patrão', workplace: 'ULSCoimbra' },
];

const TecCommission = () => {
  return (
    <div className="commission-container">
      <h1 className="commission-title">Comissões técnicas</h1>
      <div className="table-wrapper">
        <table className="commission-table">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Local de Trabalho</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member, index) => (
              <tr key={index}>
                <td>{member.name}</td>
                <td>{member.workplace}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TecCommission;
