import React from 'react';
import '../App.css';

const Section = ({ title, children }) => (
  <section className="terms-of-use-section">
    <h3 className="section-title left-align">{title}</h3>
    <div className="section-content justify-text">{children}</div>
  </section>
);

const Circ = () => {
  return (
    <section className="terms-of-use">
      <h2 className="main-title">CIRC 2025</h2>
      <Section title="Sobre o Evento">
        <p>
          A II edição do Congresso Internacional de Radiologia de Coimbra- CIRC
          2025 imaging scientific talks realiza-se no Convento de São Francisco,
          nos dias 4 e 5 de abril de 2025.
        </p>
        <p>
          Esta edição é duplamente significante, porque tenta repetir o sucesso
          do ano 2023, como o maior congresso de Radiologia organizado por TSDT
          da ULS Coimbra, e continuará a fazer o seu caminho para a
          internacionalização.
        </p>
      </Section>
      <Section title="Desafios e Oportunidades">
        <p>
          Vivemos um século cheio de desafios, os serviços de saúde vivem
          revoluções, uma avalanche de opinion makers dominam os meios de
          comunicação e impõem ideias concebidas para agendas bem definidas, não
          tendo em conta todos os atores nas respetivas áreas.
        </p>
        <p>
          Ainda, na forma supersónica como a inteligência artificial invadiu o
          nosso dia a dia, conforme mostrámos no CIRC 2023, e que nos apanhou
          prevenidos porque desde a primeira hora fomos introduzindo o tema no
          nosso dia-a-dia, estando completamente instalada nos nossos
          procedimentos.
        </p>
        <p>
          Os sistemas de saúde pelo mundo fora enfrentam hoje desafios vários,
          alguns deles sem precedentes, como a crise climática. O CIRC 2025
          pretende explorar questões relevantes através de uma lente
          radiológica, reunindo investigadores, académicos e profissionais para
          discutir e debater as ameaças e as oportunidades que se apresentam na
          nossa contemporaneidade.
        </p>
        <p>
          Os serviços de radiologia têm um peso crucial nos hospitais quando
          falamos em crise climática. É através do conhecimento do tema que
          podemos participar no processo de mudança a que o futuro nos obrigará.
          E o futuro foi ontem, neste tema.
        </p>
      </Section>
      <Section title="Contribuições dos Profissionais">
        <p>
          Os profissionais de D&T devem estar atentos e oferecerem contributos
          valiosos sobre como adaptar o nosso dia-a-dia, os nossos serviços de
          forma a sobrevivermos em tempos ambientais tão sensíveis.
        </p>
        <p>
          A Comissão Organizadora do CIRC 2025 trabalha para sermos um
          acontecimento marcante, funcionando como plataforma para a troca de
          conhecimento e discussão de ideias sobre como fortalecer os serviços
          de saúde, a nossa profissão perante os acontecimentos diários, os
          riscos e incertezas do futuro.
        </p>
        <p>
          Aguardamos por isso, e temos expetativas muito positivas, pela
          participação de TR neste evento importante e estimulante.
        </p>
        <p>
          Junte-se a nós nesta jornada para explorar os desafios e oportunidades
          do futuro.
        </p>
      </Section>
    </section>
  );
};

export default Circ;
