// src/components/js/Prices.js
import React, { useState } from 'react';
import '../css/Prices.css';
import Popup from './Popup'; // Import Popup component

function Prices() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [message, setMessage] = useState('');

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleAcceptTerms = () => {
    window.location.href = 'https://circ.eventqualia.net/pt/inicio/';
  };

  const handleButtonClick = () => {
    setMessage('Obrigado por avançar com a inscrição!');
    togglePopup();
  };

  return (
    <section className="prices">
      <h2>Inscrições e Submissões</h2>
      <p className="deadline">Até 31 de Janeiro de 2025</p>

      <div className="price-cards">
        <div className="card">
          <h3>Congressistas ULS Coimbra</h3>
          <p className="price">€40</p>
          <p>Congresso</p>
          <p className="highlight">Congresso + Jantar: €75</p>
          <button className="btn" onClick={handleButtonClick}>
            Avançar
          </button>
        </div>
        <div className="card selected">
          <h3>Congressistas Externos</h3>
          <p className="price">€80</p>
          <p>Congresso</p>
          <p className="highlight">Congresso + Jantar: €115</p>
          <button className="btn" onClick={handleButtonClick}>
            Avançar
          </button>
        </div>
        <div className="card">
          <h3>Estudantes IMR</h3>
          <p className="price">€60</p>
          <p>Congresso</p>
          <p className="highlight">Congresso + Jantar: €95</p>
          <button className="btn" onClick={handleButtonClick}>
            Avançar
          </button>
        </div>
        <div className="card">
          <h3>Congresso Virtual</h3>
          <p className="price">€60</p>
          <p>Congresso</p>
          <p className="note">
            Nota: A inscrição virtual encerra em 31 de Março de 2025.
          </p>
          <button className="btn" onClick={handleButtonClick}>
            Avançar
          </button>
        </div>
        <div className="card">
          <h3>Pacote Extra de Jantar</h3>
          <p className="price">€35</p>

          <button className="btn" onClick={handleButtonClick}>
            Avançar
          </button>
        </div>
      </div>

      <p className="deadline">A partir de 1 de fevereiro de 2025</p>
      <div className="price-cards">
        <div className="card">
          <h3>Congressistas ULS Coimbra</h3>
          <p className="price">€80</p>
          <p className="highlight">Com jantar: €115</p>
        </div>
        <div className="card selected">
          <h3>Congressistas Externos</h3>
          <p className="price">€160</p>
          <p className="highlight">Com jantar: €195</p>
        </div>
        <div className="card">
          <h3>Estudantes IMR</h3>
          <p className="price">€120</p>
          <p className="highlight">Com jantar: €155</p>
        </div>
      </div>

      <div className="message">{message}</div>
      <Popup
        isOpen={isPopupOpen}
        togglePopup={togglePopup}
        handleAcceptTerms={handleAcceptTerms}
        handleViewRegulations={() => {}}
      />
    </section>
  );
}

export default Prices;
