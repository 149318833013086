import React from 'react';
import '../css/About.css';

function About() {
  return (
    <section className="about">
      <div className="about-content">
        <div className="about-left">
          <img
            src="/cartaz_v2.jpg"
            alt="CIRC 2025 Poster"
            className="about-image"
          />
        </div>
        <div className="about-right">
          <h2>Bem vindo ao CIRC 2025</h2>
          <p>
            A II edição do Congresso Internacional de Radiologia de Coimbra -
            CIRC 2025 imaging scientific talks realiza-se no Convento de São
            Francisco, nos dias 4 e 5 de abril de 2025.
          </p>
          <p>
            Esta edição é duplamente significante, porque tenta repetir o
            sucesso do ano 2023, como o maior congresso de Radiologia organizado
            por TSDT da ULS Coimbra, e continuará a fazer o seu caminho para a
            internacionalização.
          </p>
          <p>
            Participe e colabore connosco em{' '}
            <span className="highlight">Coimbra</span>, e faça parte do futuro
            da radiologia!
          </p>
          <p className="eyes-on">
            <span className="highlight">Eyes on the future!</span>
          </p>
          <div className="about-buttons">
            <a
              href="/cartaz.pdf"
              download="cartaz.pdf"
              className="download-link"
            >
              ← Descarregar poster
            </a>
            <a href="/circ" className="more-info-link">
              Ver mais →
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
